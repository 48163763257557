import React from "react"
import { Link } from "gatsby"
import { Button, Flex, Heading } from "rebass"
import styled from "@emotion/styled"

const StyledLink = styled(Link)`
  color: white;
  position: relative;
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  font-family: ${({ theme }) => theme.fonts.body};
  margin-bottom: ${({ theme }) => theme.space[4]}px;
`

const Container = styled(Flex)`
  display: ${({ open }) => (open ? "flex" : "none")};
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
`

export default ({ open, close }) => (
  <Container
    onClick={close}
    open={open}
    bg="primary"
    alignItems="center"
    flexDirection="column"
  >
    <Heading fontFamily="body" color="white" mt={3} mb={5} fontSize={5}>
      Menu
    </Heading>
    <StyledLink to="/">Home</StyledLink>
    <StyledLink to="/about/">About</StyledLink>
    <StyledLink to="/services/">Services</StyledLink>
    <StyledLink to="/contact/">Contact</StyledLink>
    <Button variant="primary" as="a" href="tel:0429453806">
      0429 453 806
    </Button>
  </Container>
)
