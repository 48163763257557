/** @jsx jsx */
import React, { useState } from "react"
import { Flex, Button, Text } from "rebass"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { FaBars } from "react-icons/fa"
import { IconContext } from "react-icons"
import { jsx } from "theme-ui"
import Menu from "../menu/menu"
import phone from "../../images/phone.png"

const StyledLink = styled(Link)`
  color: white;
  position: relative;
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  font-family: ${({ theme }) => theme.fonts.body};
  margin-left: ${({ theme }) => theme.space[4]}px;
  padding-bottom: ${({ theme }) => theme.space[2]}px;
  :hover {
    text-decoration: underline;
  }
`

const Dropdown = styled(Flex)`
  display: none;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.primary};
  top: ${({ theme }) => theme.space[4]}px;
  left: -${({ theme }) => theme.space[2]}px;
  transition: all 0.5s ease;
  min-width: 150px;
  z-index: 1;
`

const DropdownLink = styled(StyledLink)`
  margin-left: ${({ theme }) => theme.space[2]}px;
  :hover {
    text-decoration: underline;
  }
`

const DropdownContainer = styled.div`
  padding-bottom: 20px;
  position: relative;
  display: inline-block;
  :hover div,
  :focus div,
  div:hover,
  div:focus-within div {
    display: flex;
  }
`

const PhoneIcon = styled.img`
  height: 32px;
  display: inline;
  margin-bottom: 0;
`

export const Header = () => {
  const [isModalOpen, setModalOpen] = useState(false)
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 64) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <header
        sx={{
          backgroundColor: "primary",
          py: 3,
          px: [2, 3, 3, 5],
        }}
      >
        <Flex alignItems="center">
          <Img alt="logo" fixed={logo.childImageSharp.fixed} fadeIn={false} />
          <Link
            to="/"
            sx={{
              color: "white",
              fontFamily: "heading",
              lineHeight: "heading",
              textDecoration: "none",
              fontSize: 4,
              mb: 0,
              ml: 3,
            }}
          >
            Melanie Couper <br />
            Physiotherapy
          </Link>
          <nav
            sx={{
              ml: "auto",
              display: ["none", "none", "block"],
            }}
          >
            <StyledLink as to="/about/">
              About
            </StyledLink>
            <StyledLink to="/services/">Services</StyledLink>
            <DropdownContainer>
              <Text variant="headerLink" tabIndex="0">
                Locations
                <Dropdown flexDirection="column">
                  <DropdownLink to="/dorrigo">Dorrigo</DropdownLink>
                  <DropdownLink to="/bellingen">Bellingen</DropdownLink>
                  <DropdownLink to="/coffs-harbour">Coffs Harbour</DropdownLink>
                  <DropdownLink to="/thora">Thora</DropdownLink>
                </Dropdown>
              </Text>
            </DropdownContainer>
            <StyledLink to="/contact/">Contact</StyledLink>
            <Button variant="primary" as="a" ml={4} href="tel:0429453806">
              <Flex alignItems="center">
                <PhoneIcon src={phone} alt="phone." />
                0429 453 806
              </Flex>
            </Button>
          </nav>
          <IconContext.Provider value={{ size: "40px" }}>
            <FaBars
              onClick={() => setModalOpen(true)}
              sx={{
                ml: "auto",
                display: ["block", "block", "none"],
                color: "white",
              }}
            />
          </IconContext.Provider>
        </Flex>
      </header>
      <Menu open={isModalOpen} close={() => setModalOpen(false)} />
    </React.Fragment>
  )
}
