/** @jsx jsx */
import { jsx } from "theme-ui"
import { Tiles } from "@rebass/layout"
import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby"
import { Box, Flex, Heading, Link } from "rebass"
import Img from "gatsby-image"
import footerLinks from "../../data/footer"

export const Footer = () => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-in-circle.png" }) {
        childImageSharp {
          fixed(height: 64) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <footer
      sx={{
        backgroundColor: "earth",
        py: 4,
        px: [2, 3, 3, 5],
        display: ["none", "block"],
      }}
    >
      <Tiles columns={4} maxWidth={1000} mx="auto">
        {footerLinks.map(({ key, title, links }) => (
          <Flex key={key} flexDirection="column">
            <Heading fontFamily="body" color="darkGrey" fontSize={1} mb={4}>
              {title}
            </Heading>
            {links.map(({ key, text, link }) => (
              <Link
                key={key}
                as={GatsbyLink}
                to={link}
                color="darkGrey"
                fontFamily="body"
                fontSize={1}
                mb={2}
              >
                {text}
              </Link>
            ))}
          </Flex>
        ))}
        <Box textAlign="center">
          <Img alt="logo" fixed={logo.childImageSharp.fixed} fadeIn={false} />
          <Heading
            fontFamily="heading"
            color="primary"
            fontWeight="bodyHeavy"
            fontSize={3}
            mb={2}
          >
            Melanie Couper <br /> Physiotherapy
          </Heading>
        </Box>
      </Tiles>
    </footer>
  )
}
