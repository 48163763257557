export default [
  {
    key: "section-quick-links",
    title: "Quick Links",
    links: [
      {
        key: "1-dorrigo",
        text: "Dorrigo Phyisotherapy",
        link: "/dorrigo",
      },
      {
        key: "2-bellingen",
        text: "Bellingen Phyisotherapy",
        link: "/bellingen",
      },
      {
        key: "3-coffs",
        text: "Coffs Harbour Phyisotherapy",
        link: "/coffs-harbour",
      },
      {
        key: "4-thora",
        text: "Thora Phyisotherapy",
        link: "/thora",
      },
    ],
  },
  {
    key: "section-services",
    title: "Services",
    links: [
      {
        key: "1-womens-health",
        text: "Women’s & Men’s Health",
        link: "/services",
      },
      {
        key: "3-aging",
        text: "Ageing ably",
        link: "/services",
      },
      {
        key: "4-musculosketal",
        text: "Musculosketal Physiotherapy",
        link: "/services",
      },
    ],
  },
  {
    key: "section-locations",
    title: "Locations",
    links: [
      {
        key: "1-dorrigo-well",
        text: "Dorrigo Health and Wellbeing",
        link: "/dorrigo",
      },
      {
        key: "2-coffs-centre",
        text: "Coffs Harbour Women’s Health Centre",
        link: "/coffs-harbour",
      },
      {
        key: "3-three-rivers",
        text: "Three Rivers Health, Bellingen",
        link: "/bellingen",
      },
      {
        key: "4-thora-location",
        text: "Thora Physiotherapy",
        link: "/thora",
      },
    ],
  },
]
